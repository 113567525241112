<template>
	<div class="color">
		<div class="color__swatch" :style="'background:' + color + ';'" >
			<div class="color__line"></div>
		</div>
		<div class="color__titles">
			<Heading size="6" margin="none">{{title}}</Heading>
			<Paragraph micro>{{color}}</Paragraph>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: String,
		color: String
	},
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';

.color{
	display: flex;
	align-items: center;
}
.color__swatch{
	height: 60px;
	width: 60px;
	border-radius: 50%;
	position: relative;
	@include breakpoint(sm){
		height: 120px;
		width: 120px;
		border: 2px solid #9F6F73;
	}
}
.color__line{
	position: absolute;
	top: 31px;
	height: 1px;
	width: 45px;
	background: #9F6F73;
	left: 31px;

	@include breakpoint(sm){
		top: 61px;
		height: 1px;
		width: 90px;
		left: 61px;
	}
}
.color__line::before{
	content: ' ';
	height: 5px;
	width: 5px;
	border-radius: 50%;
	background: #9F6F73;
	position: absolute;
	top: -2px;
	left: 0px;
}
.color__titles{
	margin-left: 20px;
	@include breakpoint(sm){
		margin-left: 40px;
	}
}
</style>

<!-- Used in : AR -->